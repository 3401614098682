import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.js'; // or .min.js

// // $($(".time-wrapper").children(".time-local")[0]).data("timestamp")
       
//     $.each($(".time-wrapper"), function(index, a_time){
//         // console.log(a_time);
//         local_time = $($(a_time).children(".time-local")[0]).data("timestamp") * 1000;
//         z = moment.tz.guess(true)
//         if(z!=="Europe/London"){
//             m = moment(local_time).tz(z);
//             mtz = moment.tz.zone(moment.tz.guess(true)).abbr(m);
//             $($(a_time).children(".time-remote")[0]).html(m.format("HH:mm ZZ") + " "+mtz+" (Your time, estimated)");
//         }
//     });

// try {
    const timeWrappers = document.querySelectorAll('.time-wrapper');
    
    timeWrappers.forEach(timeWrapper => {
        const localTimeElement = timeWrapper.querySelector('.time-local');
        const remoteTimeElement = timeWrapper.querySelector('.time-remote');
        
        if (localTimeElement && remoteTimeElement) {
            const localTime = parseInt(localTimeElement.dataset.timestamp) * 1000;
            const userTimeZone = moment.tz.guess(true);
            console.log(localTimeElement.dataset.timestamp, userTimeZone,localTime);
            if (userTimeZone !== "Europe/London") {
                const m = moment(localTime).tz(userTimeZone);
                const mtz = moment.tz.zone(userTimeZone).abbr(m);
                remoteTimeElement.textContent = `Approximately ${m.format("HH:mm")} ${mtz}`;
            }
        }
    });
// } catch (e) {
//     // functionToHandleError(e);
// }