import Cookies from 'js-cookie';
import "./ahoy"

try{
  var rules = [
    // if it says it's a webview, let's go with that
    'WebView',
    // iOS webview will be the same as safari but missing "Safari"
    '(iPhone|iPod|iPad)(?!.*Safari)',
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
    'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
    // old chrome android webview agent
    'Linux; U; Android'
  ]
  var webviewRegExp = new RegExp('(' + rules.join('|') + ')', 'ig')

  window.isWebview = function(ua) {
    return !!ua.match(webviewRegExp)
  }

  if (isWebview(window.navigator.userAgent)){
    if (!Cookies.get('user_has_been_issued_webview_warning')){
      alert("Performance notice: You appear to be viewing this website through an in-app browser. This does not lead to good performance outcomes - please load the site directly in a web browser rather than by clicking a link in the Facebook App, for example. If this is not the case, please let us know and we'll do our best to update this message so it doesn't bother you again. This message will be shown again tomorrow.");
    }
    Cookies.set('user_has_been_issued_webview_warning', true, 18*3600) // set cookie to not show this message for 18 hours - i.e. a day in streaming terms
      console.log(window.jQuery("html").addClass("webview-browser"));
    ahoy.track('WebView Notification', {});
  } else {
    // carry on
  }

  
}catch(e){
    
  // functionToHandleError(e);

}
