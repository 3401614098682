/* replace the x-data x-show alpine.js menu behaviour on mobile devices, to remove otherwise unneccesary alpine.js requirement */
//   document.addEventListener("DOMContentLoaded", function() {
//     menuVisible = false
//     menuButton = $('.menuOpener, .menuCloser')
//     menuContainer = $('.menuContainer')
//     menuButton.click(function(){
//       console.log("clicked ", this, menuVisible)
//       menuVisible = !menuVisible
//       menuButton.toggleClass('hidden')
//       menuContainer.toggleClass('hidden', !menuVisible)
//     })

const menuContainer = document.getElementsByClassName("menuContainer")
const buttons = document.querySelectorAll(".menuOpener,.menuCloser");

buttons.forEach(function(button) {
    button.addEventListener("click", function() {
        menuContainer[0].classList.toggle('hidden')
        buttons.forEach(function(bn){
            bn.classList.toggle('hidden')
        });
    });
});
