import "./controllers/index-application.js"
// import { Turbo } from "@hotwired/turbo-rails"
// import "@hotwired/turbo-rails"
// Turbo.session.drive = false

// import "trix"
// import "@rails/actiontext"

// import "./controllers"
// import "./controllers/editor_controller.js"

// require("@rails/ujs").start()
require("@rails/activestorage").start()
// require("channels")

// import "./custom/jquery.js" // we do this to ensure jquery window var is available across all scripts (imports are done first)
import "./custom/ahoy.js"

// custom
import "./custom/payment.js"
import "./custom/events.js"
import "./custom/fixtures.js"

window.Cookies = require("js-cookie")

// import "./tools/modernizr.js"
import "./custom/webview_detect.js"
import "./custom/timezone.js"

// Make use of Modernizr
if (window.CSS && CSS.supports('color', 'var(--fake-var)')) {
  // we're good
} else {
  document.getElementById("html").className += "no-bgvar-support simple-site";
}
