// magic
import Rails from '@rails/ujs';
import "./ahoy"

const checkoutButtons = document.querySelectorAll(".checkoutButton"),
startPayment = function(btn){

  var 
  payable_type=btn.dataset.payabletype,
  payable_id=btn.dataset.payableid,
  auth_token=document.querySelector('meta[name~="csrf-token"][content]').content;

  // check for blanks in any of these three variables and throw an error if so

  Rails.ajax({
    url: '/create-checkout-session?payable_type='+payable_type+'&payable_id='+payable_id,
    type: 'post',
    data: {},
    headers: {
      'X-CSRF-Token': auth_token
    },
    dataType: 'json',
    success: function (session) {
      console.log(session);
      return stripe.redirectToCheckout({ sessionId: session.id });
    },
    error: function(error) {
      alert("Your attempt to pay was unsuccessful please reload the page and try again in a few moments. If this continues, you may wish to try logging in with another email address. Contact support@infinity21.net or use the help widget for assistance.");
      console.error('Error:', error);
    },
    complete: function(xhr, status) {
      // console.log(xhr, status);
    }
  });
},
paymentButtonClick = function(btn){
  btn.addEventListener("click", (event) => {
    event.preventDefault();
    toggleButton(btn); 
    startPayment(btn); 
  });

  // .addEventListener("click", function(ev){ console.log('click'); toggleButton(); startPayment(); });
},
toggleButton = function(btn){
  // console.log("toggle button")
  btn.disabled = true;
  btn.innerHTML = "<span>Please wait, redirecting to payment site</span><span class='text-sm block'>Please reload the page if this takes too long!</span>"
  btn.style.backgroundColor="#cccccc";
  btn.style.color="#000";
},
makeButtonAvailable = function(btn){
  btn.disabled=false;
  btn.innerHTML = btn.dataset.cta;
  btn.style.backgroundColor="rgb(22 101 52)";
},
setupPayment = (function(){
  checkoutButtons.forEach(function(btn){
    paymentButtonClick(btn)
    makeButtonAvailable(btn)
  })
  // do it now ()
})();
